<template lang="pug">
.absolute.bg-white.px-6(style='width: 98vw; height: 99vh; z-index:99')
  .flex.justify-between.mt-5.mb-4
    p.truncate.text-darkblue.text-base.font-bold(style='font-size: 13px') {{ tableName + ' , ' + name}}
    .search.flex.relative(style='height: 35px; width:500px',)
      .absolute.inset-y-0.t-0.r-0.flex.items-center.cursor-pointer(
          style='right: 8px',
        @click='searchFinance'
        )
          svg(
            width='24',
            height='24',
            fill='none',
            xmlns='http://www.w3.org/2000/svg'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M17.6 9.8a7.8 7.8 0 10-7.8 7.8 7.819 7.819 0 005.076-1.872l.324.324V17l6 6 1.8-1.8-6-6h-.948l-.324-.324A7.819 7.819 0 0017.6 9.8zm-13.201 0c0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4z',
              fill='#CFDBEB'
            )
      input.rounded.focus_outline-none.pl-4.pr-8.w-full.text-sm.font-medium.border.border-borderColor(
          type='text',
          @keyup.enter='searchFinance',
          v-model.lazy='searchString'
        )
    .flex

      el-popover(width='220', trigger='hover', popper-class='popoverLayers')

        a.mt-2.ml-2.mb-2.cursor-pointer(style='font-size: 14px' target='_blank' href="https://app.skif.pro/api_v1/finance_excel?body=%7B%7D") Скачать отчет
        svg.cursor-pointer(
            width='25',
            height='25',
            slot='reference',
            fill='none',
            xmlns='http://www.w3.org/2000/svg'
          )
            path(
              d='M12.5 10.945c-.197 0-.392.04-.574.118a1.497 1.497 0 00-.487.337 1.604 1.604 0 000 2.199c.14.145.305.26.487.338a1.453 1.453 0 001.635-.338c.28-.291.439-.687.439-1.1 0-.412-.159-.807-.44-1.099a1.474 1.474 0 00-1.06-.455zm0-1.837c.197 0 .392-.04.574-.118.182-.078.347-.193.486-.337.14-.145.25-.316.325-.505a1.605 1.605 0 00-.326-1.693 1.496 1.496 0 00-.487-.337 1.453 1.453 0 00-1.633.338A1.583 1.583 0 0011 7.555c0 .412.158.807.44 1.099.28.291.662.455 1.06.454zm0 6.782c-.398 0-.78.164-1.06.456-.282.291-.44.687-.44 1.1 0 .412.158.807.44 1.099.28.291.662.455 1.06.455s.78-.164 1.06-.455c.282-.292.44-.688.44-1.1 0-.412-.158-.808-.44-1.1a1.474 1.474 0 00-1.06-.455z',
              fill='#5477A9'
            )
            defs
              clippath#clip0
                path(fill='#fff', transform='translate(11 6)', d='M0 0h3v13H0z')
            //- )
      i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold.ml-3(
        @click='$emit("close")'
      )
  .el-loading-spinner(v-if='loading')
      svg.circular(viewBox='25 25 50 50')
         circle.path(cx='50', cy='50', r='20', fill='none')
  .relative.overflow-auto(:class='loading?"light": "" ' ,style='height:90vh; width:100%')
    .table.w-full.text-darkblue.trTable.font-SourceSansPro
      thead.text-left.mb-2
         tr.pb-2
          td.top-0.sticky.bg-white.px-4(
            v-for='(column,index) in columnTable',
            :key='index'
          )
            .flex.items-center
              span.text-md.leading-0.font-bold.whitespace-no-wrap {{ column.name }}
              svg.transition-transform.ease-in-out.duration-500.ml-3(
                v-if='column.is_sortable',
                width='24',
                height='24',
                fill='none',
                :style='sortProp === column.key && sortOrder === "descending" && { transform: "scale(1,-1)" }',
                xmlns='http://www.w3.org/2000/svg',
                @click='sortChange(column.key)'
              )
                path.transition-colors.ease-in-out.duration-200(
                  d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                  :fill='sortProp === column.key ? "#3C5E8F" : "#91B4E7"'
                )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                    :fill='sortProp === column.key ? "#3C5E8F" : "#91B4E7"'
                  )
      tbody
        tr.cursor-pointer.hover_bg-hoverrow(
          v-for='(row, index) in sortedRows',
          :key='index',
          @click='currentIndex = index',
        )

          td.px-4(v-for='cell in columnTable', class='height:1px !important')
            span.font-semibold.whitespace-no-wrap.table-text(
            ) {{ row[cell.key] }}
            //-   :class='row._is_total ? "font-bold" : ""'
             //- :class='{ isRowSelected: index == isRowClicked }'
</template>

<script>
import { adminPanel } from '@/api'
import eventBus from '@/eventBus.js'

export default {
  data() {
    return {
      columnTable: [],
      sortedRows: [],
      loading: true,
      searchString: '',
      tableName: '',
      name: '',
      currentIndex: ''
    }
  },
  methods: {
    async searchFinance() {
      this.loading = true
      await adminPanel.getFinance(
        this.searchString,
        (response) => {
          this.tableName = response.data.group_name
          this.columnTable = response.data.report.columns
          this.sortedRows = response.data.report.rows
          this.name = response.data.report.name

          this.loading = false
        },
        (error) => {
          console.log('lego', error)
          eventBus.$showError(error.response.data.message)
        }
      )
    },
    downloadReport() {
      console.log('download report')
    }
  },
  async mounted() {
    const searchString = ''
    await adminPanel.getFinance(
      searchString,
      (response) => {
        this.tableName = response.data.group_name
        this.columnTable = response.data.report.columns
        this.sortedRows = response.data.report.rows
        this.name = response.data.report.name

        this.loading = false
      },
      (error) => {
        console.log('error', error)
        eventBus.$showError(error.response.data.message)
      }
    )
  }
}
</script>
<style scoped>
.light {
  opacity: 0.2;
}
.table-text {
  font-size: 12px;
}
.trTable tr > td {
  height: 1px;
  line-height: 1.1;
  font-size: 12px;
  color: #5477a9 !important;
}
</style>
